<template>
  <div class="gwp-container">
    <div :class="isCartPage ? 'h4' : 'h5'">
      {{ cartStore.totalPrice < lowestPrice ? giftsStore.unlockMessage : giftsStore.claimMessage }}
    </div>
    <p class="mgn-btm-1">
      <template v-if="cartStore.totalPrice < lowestPrice">
        You’re
        <span class="text-highlight">{{
          localeStore.currencySymbol + (lowestPrice - cartStore.totalPrice).toFixed(2)
        }}</span>
        away from unlocking your <strong>free gift.</strong>
      </template>
      <template v-else>
        <span class="text-highlight">Congrats!</span> You’ve unlocked a free gift. Choose One.
      </template>
    </p>

    <div class="gwp-items-container">
      <ul class="gwp-items flex-inline">
        <li
          v-for="(item, index) in giftsStore.products"
          :key="item.product.url + index"
          :class="[
            'flex-inline gwp-tile',
            { 'enabled pointer': checkEnabled(item) },
            { disabled: cartStore.totalPrice < item.minimumSpend },
            { selected: checkIfSelected(item) },
            { 'transition-selected': state.transitionIndex === index },
          ]"
          @click="addToCartAction(item, index)"
        >
          <div class="overlay" />
          <LockLine v-if="cartStore.totalPrice < item.minimumSpend" class="lock" />
          <CloudinaryImage
            :public-id="getAssetPublicId(item.product.thumb)"
            :options="{ width: 80, height: 80, quality: 'auto:eco' }"
            :alt="item.product.display_title"
          />
          <div class="flex-col">
            <span class="title">{{
              item.product.display_title + (item.quantity && item.quantity > 1 ? ` (${item.quantity})` : '')
            }}</span>
            <span class="desc">
              <template v-if="checkEnabled(item) || checkIfSelected(item)">
                <span class="strikethrough">{{
                  localeStore.currencySymbol +
                  (item?.product?.product_offerings?.bc_primary_product?.product?.price ||
                    item?.product?.product_offerings?.bc_primary_product?.product?.sale_price) *
                    item.quantity
                }}</span
                ><span class="text">FREE</span>
              </template>
              <template v-else>
                <span class="info">Spend {{ localeStore.currencySymbol + item.minimumSpend }}+ to unlock</span>
              </template>
            </span>
          </div>
          <CheckboxCircleFill v-if="checkIfSelected(item)" class="check" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getAssetPublicId } from '~/util/contentstack/csHelpers';
// import { CartObj } from '~/types/bigcommerce';
import { scrollToTop } from '~/util/eventHandler';
import { useGiftsStore } from '~/store/gifts';
// import { useConnectionStore } from '~/store/connection';
import { useLocaleStore } from '~/store/locale';
import { useCartStore } from '~/store/cart';

// const connectionStore = useConnectionStore();
const giftsStore = useGiftsStore();
const localeStore = useLocaleStore();
const cartStore = useCartStore();
const route = useRoute();

defineProps({
  isCartPage: {
    type: Boolean,
    default: false,
  },
});

const state = reactive({
  transitionIndex: null as number | null,
});

const lowestPrice = computed<number>(() => {
  let lowestPrice = 1000000000;
  for (let i = 0; i < giftsStore.products.length; i++) {
    if (giftsStore.products[i]?.minimumSpend < lowestPrice) {
      lowestPrice = giftsStore.products[i]?.minimumSpend;
    }
  }
  return lowestPrice;
});
const gwpSelected = computed<number>(() => {
  const gwpInCart = giftsStore.products.map((item: any) => {
    return checkIfProductInCart(item);
  });
  return gwpInCart.indexOf(true);
});

async function addToCartAction(item: any, index: number) {
  state.transitionIndex = index;
  const image = item?.product?.thumb;
  const variants = item?.product?.option_variants?.variations;
  const hasVariants = variants?.length;
  const variant = hasVariants ? variants?.[0]?.bc_variant : null;
  const sku = hasVariants ? variant.sku : item?.product.sku;
  const parentSku = hasVariants ? item?.product.sku : null;
  const cartObj: any[] = [
    {
      quantity: item?.quantity || 1,
      product: {
        ...item?.product,
        image,
        sku,
        parentSku,
      },
      hasVariants,
      ...(hasVariants && { variant: mapVariantForCart(variants?.[0]?.bc_variant) }),
    },
  ];

  // Remove previous GWP Item that was selected if a new one was
  if (gwpSelected.value > -1 && index !== gwpSelected.value) {
    const gwpProd = giftsStore.products[gwpSelected.value]?.product;
    const cartProdIndex = cartStore.products?.findIndex((item: any) => {
      return (
        item?.id === gwpProd?.product?.product_offerings?.bc_primary_product?.product?.id && item?.price?.regular === 0
      );
    });
    await useDeleteCartItem({ cartItem: { product: cartStore.products[cartProdIndex] } });
  }

  if (index !== gwpSelected.value) await useAddToCart({ cartItems: cartObj, setLatestAdditions: false });
  state.transitionIndex = null;
  if (route.path === '/' + localeStore.langCode + '/cart') {
    scrollToTop();
    cartStore.setNewItemAdded(false);
  }
}
function mapVariantForCart(variant: any) {
  return {
    ...variant,
    id: variant?.id,
    product_id: variant?.product_id,
    bcId: variant?.id,
    name: variant?.name,
    sku: variant?.sku,
    cost_price: variant?.cost_price,
    calculated_price: variant?.calculated_price,
    is_default_variant: variant?.is_default_variant,
    options: variant?.options,
    optionDesc: variant?.optionSelectionDesc,
  };
}
function checkIfSelected(item: any): boolean {
  return checkIfProductInCart(item) && cartStore.totalPrice >= item?.minimumSpend;
}
function checkEnabled(item: any): boolean {
  return !checkIfProductInCart(item) && cartStore.totalPrice >= item?.minimumSpend;
}
// TODO - handle variants ????
function checkIfProductInCart(rule: any): boolean {
  const found = cartStore.products?.find((cartProd: any) => {
    const idMatch = cartProd?.id === rule?.product?.product_offerings?.bc_primary_product?.product?.id;
    if (idMatch) {
      return cartProd?.price?.regular === 0 && cartProd?.qty === rule?.quantity;
    }
    return false;
  });
  return !!found;
}
</script>

<style lang="scss" scoped>
.gwp-container {
  padding: 1.5rem 0;
  border-top: 1px solid $color-neutral-cool-100;
  &.cart-page {
    @include local-mixins.desktop {
      padding: 2.5rem 0;
    }
  }
}
.gwp-items-container {
  overflow-x: auto;
}
.gwp-items {
  gap: 8px;
  position: relative;
  padding-bottom: 0.5rem;
}
.text-highlight {
  color: $color-primary-600;
  font-weight: 600;
}
.gwp-tile {
  position: relative;
  width: 280px;
  min-width: 280px;
  height: 96px;
  padding: 8px;
  border: 1px solid $color-neutral-cool-100;
  border-radius: 4px;
  .title {
    font-weight: 500;
    font-size: 1rem;
  }
  .desc,
  .desc .strikethrough {
    font-size: 0.875rem;
    line-height: 1.5;
    .text {
      margin-left: 8px;
    }
    .info {
      font-size: 0.75rem;
    }
  }
  .overlay {
    display: none;
  }
  .check,
  .lock {
    position: absolute;
    top: 8px;
    height: 16px;
    width: 16px;
  }
  .check {
    right: 8px;
    fill: $color-secondary-500;
  }
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: $color-neutral-cool-100;
    .overlay {
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      // z-index: map.get(local-vars.$zindex, 'page');
      margin-left: -8px;
    }
  }
  &.selected {
    border: 2px solid $color-secondary-500;
  }
  &.transition-selected:not(.selected):not(.disabled) {
    border: 1px solid $color-secondary-300;
  }
}
.image {
  display: flex;
  margin: 0 1rem 0 0;
  width: 80px;
  height: auto;
  border-radius: 4px;
  background-color: $color-neutral-cool-50;
}
</style>
